export const DynamicHeaders = () => {
  return (
    <thead className="border-b-2 border-blue-dark">
      <tr>
        <th
          scope="col"
          className="px-6 py-3 text-left text-xs font-bold text-blue-dark tracking-wider"
        >
          UserID
        </th>
        <th
          scope="col"
          className="px-6 py-3 text-left text-xs font-bold text-blue-dark tracking-wider"
        >
          Since
        </th>
        <th
          scope="col"
          className="px-6 py-3 text-left text-xs font-bold text-blue-dark tracking-wider"
        >
          Customer
        </th>
        <th
          scope="col"
          className="px-6 py-3 text-left text-xs font-bold text-blue-dark tracking-wider"
        >
          Status
        </th>
      </tr>
    </thead>
  );
};
