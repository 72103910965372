import axios from "axios";

type ButtonProps = {
  customerID?: string;
  coffee?: any;
  allData?: any;
  text?: string;
  setModal: any;
  setModalName: any;
  setCustomerID: any;
};

export const RefillButton = ({
  text = "Refill Customer",
  customerID,
  coffee,
  allData,
  setModal,
  setModalName,
  setCustomerID
}: ButtonProps) => {
  const onRefillClick = () => {
    setModal(true);
    setModalName(allData.customerName);
    setCustomerID(customerID)
  };

  return (
    <button
      onClick={onRefillClick}
      className="rounded-md w-auto text-sm font-semibold bg-black border border-black text-white py-3 px-4 hover:bg-white hover:text-black "
    >
      {text}
    </button>
  );
};
