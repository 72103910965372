import axios from "axios";
import { useState } from "react";
import refilledIcon from "../assets/refilled-icon.png";

type ButtonProps = {
  customerID?: string;
  coffee?: any;
  allData?: any;
  itemData?: any;
  setRefillQuantity?: any;
  refillQuantity: number;
  text?: string;
};

export const RefillButtonConfirm = ({
  customerID,
  coffee,
  allData,
  itemData,
  setRefillQuantity,
  refillQuantity,
}: ButtonProps) => {

  const [showRefilledButton, setShowRefilledButton] = useState(false);
  const [handleDisable, setHandleDisable] = useState(
    refillQuantity < 1 ? true : false
  );
  const getButtonText = (coffee: string) => {
    let text = "Refill ";
    switch (coffee) {
      case "container":
        text = "Give Container";
        break;
      default:
        text = text + coffee.charAt(0).toUpperCase() + coffee.slice(1);
        break;
    }
    return text;
  };

  const onRefillClick = (cancelRefill: boolean) => {
    setHandleDisable(true);
    allData.data[itemData[0]].single = cancelRefill
      ? allData.data[itemData[0]].single + 1
      : allData.data[itemData[0]].single - 1;

    const userData = {
      customerID: customerID,
      coffeeName: coffee,
      allData: allData.data,
    };
    const headers = { "Access-Control-Allow-Origin": "*" };

    axios
      .post(
        `https://us-central1-progeny-coffee-prod.cloudfunctions.net/app/refill-coffee`,
        userData,
        {
          headers: headers,
        }
      )
      .then((res) => {
        setRefillQuantity(res.data.allData[itemData[0]].single);
        setShowRefilledButton(!cancelRefill);
        setHandleDisable(false);
      });
  };

  const returnRefillButton = () => {
    if (showRefilledButton) {
      return (
        <>
          <button
            onClick={() => onRefillClick(true)}
            className="flex items-center justify-center rounded-md text-sm font-semibold bg-green-refilled border border-green-refilled text-white py-3 px-4 w-44 h-12"
            disabled={handleDisable}
          >
            Refilled <img src={refilledIcon} className="w-5 ml-3" />
          </button>
          <span className="text-gray-normal text-xs flex justify-center mt-2 font-bold">
            Tap again to cancel
          </span>
        </>
      );
    } else {
      return (
        <button
          onClick={() => onRefillClick(false)}
          className="rounded-md text-sm font-semibold bg-black border border-black text-white py-3 px-4 hover:bg-white hover:text-black w-44 h-12"
          disabled={handleDisable}
        >
          {getButtonText(coffee)}
        </button>
      );
    }
  };

  return returnRefillButton();
};
