export type BadgeStatus =
  | 'info'
  | 'critical'
  | 'warning'
  | 'success'
  | 'comming'

type BadgeProps = {
    status: BadgeStatus
    children: string
    width?: 'auto' | 'fixed'
  }

  export const Badge = ({
    status = 'success',
    children,
    width = 'fixed',
  }: BadgeProps) => {
    let bgColor = 'bg-status-success-light'
  
    switch (status) {
      case 'info':
        bgColor = 'bg-status-info-light'
        break
      case 'critical':
        bgColor = 'bg-status-critical-light'
        break
      case 'warning':
        bgColor = 'bg-status-warning-light'
        break
    }
  
    const componentWidth = width === 'fixed' ? 'w-32' : ''
  
    if (status === 'comming') {
      return (
        <div
          className={`h-6 py-1.5 px-2.5 bg-blue-default rounded-full text-white flex text-xxs items-center uppercase`}
        >
          {children}
        </div>
      )
    }
  
    return (
      <div className={`h-6 w-auto inline-block rounded-3xl py-1 px-1.5 ${bgColor}`}>
        <div className={`flex mx-1 justify-center items-center text-blue-dark text-xs`}>
          {children}
        </div>
      </div>
    )
  }
  
  export default Badge
  