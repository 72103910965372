import container from "../assets/container.png"
import image1 from "../assets/sub_image1.jpg"
import image2 from "../assets/sub_image2.jpg"
import image3 from "../assets/sub_image3.jpg"
import image4 from "../assets/sub_image4.jpg"
import image5 from "../assets/sub_image5.jpg"
import image6 from "../assets/sub_image6.jpg"

type ImageBannerProps = {
  customerID?: string;
  coffee?: any;
  allData?: any;
  text?: string;
};

const getImageByCoffee = (coffee: string) => {
    switch (coffee) {
        case 'alegria':
            return image1
        case 'camino':
            return image2
        case 'ardiente':
            return image3
        case 'bondad':
            return image4
        case 'tropical':
            return image5
        case 'granada':
            return image6
        case 'container':
            return container
    }
}

export const ImageBanner = ({
  text = "Refill Customer",
  coffee,
}: ImageBannerProps) => {

  return (
      <div className="flex items-center">
        <div className="font-bold text-xl relative w-16">{coffee.toUpperCase()}</div>
        <div className="w-52 h-20 bg-cover bg-center bg-no-repeat p" style={{backgroundImage: `url(${getImageByCoffee(coffee)})`}} ></div>
      </div>
  );
};
