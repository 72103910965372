import React, { useEffect, useState } from "react";
import logo from "./assets/logo.png";
import "./App.css";
import { DynamicTable } from "./components/DynamicTable";
import { LoginModal } from "./components/LoginModal";

function App() {
  const [showModal, setShowModal] = useState(false);
  const [auth, setAuth] = useState<null | string>();

  const getSession = () => {
    setAuth(window.sessionStorage.getItem("auth"));
  };
  useEffect(() => {
    getSession();
    if (window.sessionStorage.getItem("auth") !== "true") {
      setAuth(window.sessionStorage.getItem("auth"));
      setShowModal(true);
    }
  });

  return (
    <>
      <div className="mt-6 mb-4 ml-4">
        <img src={logo} alt="" />
      </div>
      <DynamicTable />

      {showModal ? (
        <>
          <LoginModal setShowModal={setShowModal} />
        </>
      ) : null}
    </>
  );
}

export default App;
