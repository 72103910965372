import { DynamicBody } from "./DynamicBody";
import { DynamicHeaders } from "./DynamicHeaders";

export const DynamicTable = () => {
  return (
    <div className="flex flex-col w-full">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="overflow-hidden border-none">
            <table className="min-w-full divide-y divide-gray-200">
              <DynamicHeaders/>
              <DynamicBody/>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
