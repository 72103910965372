import { useEffect, useState } from "react";
import axios from "axios";

type LoginModalProps = {
  setShowModal?: any;
  setAuth?: any;
};
export const LoginModal = ({ setAuth, setShowModal }: LoginModalProps) => {
  useEffect(() => {
    // axios
    //   .get(
    //     `https://us-central1-progeny-coffee-prod.cloudfunctions.net/app/get-orders-count?customerID=${customerID}`
    //   )
    //   .then((res1: any) => {
    //     axios
    //       .get(
    //         `https://us-central1-progeny-coffee-prod.cloudfunctions.net/app/get-refill-data?customerID=${customerID}`
    //       )
    //       .then((res: any) => {
    //       });
    //   });
  }, []);

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-center p-5 border-b border-solid border-slate-200 rounded-t">
              <div>
                <div className="uppercase text-3xl font-bold">Login</div>
              </div>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto">
              <div className="flex flex-col items-center">
                <div className="mb-7">Please login to access the admin.</div>
                <input
                  className="w-96 h-16 border border-gray-border rounded pl-7 mb-4"
                  type="text"
                  placeholder="Email"
                />
                <input
                  className="w-96 h-16 border border-gray-border rounded pl-7 mb-4"
                  type="password"
                  placeholder="Password"
                />
                <button
                  onClick={() => {
                    window.sessionStorage.setItem("auth", "true");
                    setShowModal(false);
                  }}
                  className="rounded-md text-sm font-semibold bg-black border border-black text-white py-3 px-4 hover:bg-white hover:text-black w-44 h-12"
                >
                  Submit
                </button>
              </div>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-gray-500 background-transparent font-bold px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShowModal(false)}
              >
                If you’re having issues to login, please contact your cafe
                manager.
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
