import { useEffect, useState } from "react";
import Badge from "./Badge";
import { RefillButton } from "./RefillButton";
import axios from "axios";
import { RefillProduct } from "./RefillProduct";
import { RefillModal } from "./RefillModal";

export const DynamicBody = () => {
  const [coffeeData, setCofeeData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalName, setModalName] = useState(false);
  const [customerID, setCustomerID] = useState("");

  useEffect(() => {
    axios
      .get(
        `https://us-central1-progeny-coffee-prod.cloudfunctions.net/app/get-refill-data`
      )
      .then((res: any) => {
        setCofeeData(res.data.sort((a: any, b: any) => b.single - a.single));
      });
    axios
      .get(
        `https://us-central1-progeny-coffee-prod.cloudfunctions.net/app/get-refill-data-user`
      )
      .then((res: any) => {
        setCofeeData(res.data);
      });
  }, []);

  return (
    <>
      <tbody className="bg-white divide-y divide-gray-200 overflow-y-scroll">
        {coffeeData.map(function (item: any, id) {
          return (
            <tr key={`${id}${item}`}>
              <td className="text-xs px-6 py-4 whitespace-nowrap">
                {item.customerID}
              </td>
              <td className="text-xs px-6 py-4 whitespace-nowrap">
                13/05/2022
              </td>

              <td className="text-xs px-6 py-4 whitespace-nowrap bold font-bold">
                {item.customerName}
              </td>
              <td className="text-xs px-6 py-4 whitespace-nowrap">
                <Badge status="success">Active</Badge>
              </td>
              <td className="text-xs px-6 py-4 whitespace-nowrap">
                <RefillButton
                  customerID={item.customerID}
                  coffee={""}
                  allData={item}
                  setModal={setShowModal}
                  setModalName={setModalName}
                  setCustomerID={setCustomerID}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
      {showModal ? (
        <>
          <RefillModal
            setShowModal={setShowModal}
            modalName={modalName}
            customerID={customerID}
          />
        </>
      ) : null}
    </>
  );
};
