import { useEffect, useState } from "react";
import axios from "axios";
import { RefillProduct } from "./RefillProduct";

type RefillModalProps = {
  coffee?: any;
  customerID?: string;
  refill?: any;
  text?: string;
  setShowModal?: any;
  modalName: boolean;
};
type CoffeData = {
  data: Object;
};

export const RefillModal = ({
  customerID,
  modalName,
  setShowModal,
}: RefillModalProps) => {
  const [coffeeData, setCofeeData] = useState<any>();
  const [showContainer, setShowContainer] = useState(false);

  useEffect(() => {
    axios
      .get(
        `https://us-central1-progeny-coffee-prod.cloudfunctions.net/app/get-orders-count?customerID=${customerID}`
      )
      .then((res1: any) => {
        axios
          .get(
            `https://us-central1-progeny-coffee-prod.cloudfunctions.net/app/get-refill-data?customerID=${customerID}`
          )
          .then((res: any) => {
            setShowContainer(res1.data.first);
            setCofeeData(res.data);
          });
      });
  }, []);

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <div>
                <div>Subscription Details</div>
                <div className="uppercase text-3xl font-bold">{modalName}</div>
              </div>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto">
              {showContainer ? (
                <RefillProduct coffee="container" refill={1} />
              ) : null}
              {Object.entries(coffeeData?.data ?? []).map(function (
                item: any,
                id: any
              ) {
                return (
                  <RefillProduct
                    key={id}
                    coffee={item[0]}
                    refill={item[1].single}
                    allData={coffeeData}
                    itemData={item}
                    customerID={customerID}
                  />
                );
              })}
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-gray-500 background-transparent font-bold px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShowModal(false)}
              >
                That’s it.
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
