import axios from "axios";
import { useState } from "react";
import container from "../assets/container.png";
import { ImageBanner } from "./ImageBanner";
import { RefillButtonConfirm } from "./RefillButtonConfirm";

type RefillProductProps = {
  customerID?: string;
  coffee?: any;
  allData?: any;
  itemData?: any;
  refill?: any;
  text?: string;
};

export const RefillProduct = ({
  text = "Refill Customer",
  customerID,
  coffee,
  refill,
  allData,
  itemData,
}: RefillProductProps) => {
  const [refillQuantity, setRefillQuantity] = useState(refill);
  return (
    <div className="flex justify-between w-auto items-center mb-8">
      <div className="">
        <ImageBanner coffee={coffee} />
      </div>
      <div className="flex flex-col ml-20 mr-32 text-center">
        <div className="font-bold text-sm mb-3">Refills Left</div>
        <div className="bg-gray-back border border-gray-border text-center w-20 h-7">
          {refillQuantity}
        </div>
      </div>
      <div className="m-6">
        <RefillButtonConfirm
          coffee={coffee}
          customerID={customerID}
          allData={allData}
          itemData={itemData}
          setRefillQuantity={setRefillQuantity}
          refillQuantity={refillQuantity}
        />
      </div>
    </div>
  );
};
